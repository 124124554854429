import React from 'react'
import './index.scss';
// import NftFilterData from '../../details.json';

const NFTModal = ({modalTitle, modalImg, modalData}) => {
    return (
        <div className="modal fade" id="nftModal" tabIndex="-1" aria-labelledby="nftModal" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content bg-discord big-round">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Akita #{modalTitle}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6">
                            <img src={modalImg} className="img-fluid big-round" alt=""/>
                        </div>
                        <div className="col">
                            <table className="table table-dark table-striped">
                                <tbody>
                                <tr>
                                    <th className="px-4" scope="row">Name:</th>
                                    <td width="100%">Akita #{modalData.Name}</td>
                                </tr>
                                <tr>
                                    <th className="px-4" scope="row">Head:</th>
                                    <td width="100%">
                                        {modalData.Head ? modalData.Head : ''}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="px-4" scope="row">Eyes:</th>
                                    <td>{modalData.Eyes ? modalData.Eyes : ''}</td>
                                </tr>
                                <tr>
                                    <th className="px-4" scope="row">Snout:</th>
                                    <td>{modalData.Snout ? modalData.Snout : ''}</td>
                                </tr>
                                <tr>
                                    <th className="px-4" scope="row">Body:</th>
                                    <td>{modalData.Body ? modalData.Body : ''}</td>
                                </tr>
                                <tr>
                                    <th className="px-4" scope="row">Back:</th>
                                    <td>{modalData.Back ? modalData.Back : ''}</td>
                                </tr>
                                <tr>
                                    <th className="px-4" scope="row">Skin:</th>
                                    <td>{modalData.Skin ? modalData.Skin : ''}</td>
                                </tr>
                                <tr>
                                    <th className="px-4" scope="row">Background:</th>
                                    <td>{modalData.Background ? modalData.Background : ''}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default NFTModal
