import React from 'react'
import FAQSection from '../components/Sections/FAQSection'


const FAQ = () => {
    return (
        <>
            <FAQSection />
        </>
    )
}

export default FAQ
