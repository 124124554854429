import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import LazyLoad from "react-lazyload";
import VerifyModal from '../../VerifyModal';
import loadingImg from '../../../images/loading.webp';
import './index.scss';
import algosdk from 'algosdk';
import NftData from '../../../data.json';
import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import { openConnectModal, openWalletConnectModal, closeModal } from "../../../services/actions/actions";
import { myAlgoWallet, walletConnect } from '../../../services/reducers/connect/connect'

const akitaIDs = NftData.map(nft => nft.ID);
const nftDict = NftData.reduce((old, item) => ({ ...old, [item.ID]: item }), {});
const Algosdk = new algosdk.Algodv2('', 'https://node.algoexplorerapi.io', 443);
const Indexer = new algosdk.Indexer('', 'https://algoindexer.algoexplorerapi.io', 443);
const rewardVerificationAddress = "YDUC4QTYG33W3E5K7PQVAPP3I7J5U4CI6O3XWPXBY5A2JTPMJS5NK6U25E"
// const apiURL = "https://gateway-nft.akita.community"
const apiURL = "/api"
const startingRound = 21030318;
const endingRound = 21864594;

async function getEligible(address, akcIDs) {
    let eligible = [];
    let next_token = '';
    let num = 1;
    while (num > 0) {
        let next_page = next_token;
        let response = await Indexer.lookupAccountAssets(address).nextToken(next_page).do();
        response.assets.map((asset) => {
            if (akcIDs.includes(asset['asset-id']) && asset['amount'] === 1) {
                eligible.push(asset['asset-id']);
            }
        });
        num = response.assets.length;
        if (num > 0) {
            next_token = response['next-token'];
        }
    }

    return eligible.map(id => nftDict[id]);
}

async function getStakedInEligible(address, stakedIDS) {
    let ineligible = [];
    let next_token = '';
    let num = 1;
    while (num > 0) {
        let next_page = next_token;
        let response = await Indexer.lookupAccountTransactions(address).nextToken(next_page).minRound(startingRound).maxRound(endingRound).txType("axfer").do();
        response.transactions.slice(0).reverse().map((tx) => {
            if (!!tx && 'asset-transfer-transaction' in tx) {
                const assetTx = tx['asset-transfer-transaction'];
                if (!!assetTx && 'asset-id' in assetTx && stakedIDS.includes(assetTx['asset-id']) && 'amount' in assetTx && assetTx['amount'] > 0) {
                    ineligible.push({
                        ID: assetTx['asset-id'],
                        DisqualifyingRound: tx['confirmed-round'],
                        DisqualifyingTx: tx['id'],
                    })
                }
            }
        })
        num = response.transactions.length;
        if (num > 0) {
            next_token = response['next-token'];
        }
    }

    return ineligible.map(data => ({ ...data, ...nftDict[data.ID] }));
}

async function verifyWallet(address, token, entryID, wtype, walletConnect, myAlgoWallet, setStateFunc, setModalVisibilityFunc, setRewardsFunc) {
    setModalVisibilityFunc(true);
    setStateFunc('loading');

    const suggestedParams = await Algosdk.getTransactionParams().do();
    const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        suggestedParams,
        from: address,
        to: rewardVerificationAddress,
        amount: 0,
        note: new Uint8Array(Buffer.from(token))
    });

    if (wtype === 'pera') {
        const txnToSign = {
            txn: Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString("base64"),
            message: 'Akita Rewards Zero Amount Verification Transaction',
        };
        const request = formatJsonRpcRequest("algo_signTxn", [[txnToSign]]);
        try {
            const result = await walletConnect.sendCustomRequest(request);
            const signedTxns = result.map(element => new Uint8Array(Buffer.from(element, "base64")));
            const { txId } = await Algosdk.sendRawTransaction(signedTxns).do();
            const confirmedRound = await waitForTransaction(txId);
            // console.log(confirmedRound);

            const verifyResponse = await fetch(`${apiURL}/rewards/verify`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: entryID,
                    txn: txId
                })
            });
            const data = await verifyResponse.json();
            if (!data.ok) {
                console.error(data.error);
                setStateFunc('error');
                setModalVisibilityFunc(false);
                return;
            }
        
            setStateFunc('verified');
            setModalVisibilityFunc(false);
            // setTimeout(() => {
                setRewardsFunc(data.data);
            // }, 5000);
        } catch (error) {
            console.error(error);
            setStateFunc('error');
            setModalVisibilityFunc(false);
            return;
        }
    } else if (wtype === 'myalgo') {
        try {
            const result = await myAlgoWallet.signTransaction([txn.toByte()])
            const signedTxns = result.map(element => new Uint8Array(element.blob));
            const { txId } = await Algosdk.sendRawTransaction(signedTxns).do();
            const confirmedRound = await waitForTransaction(txId);
            // console.log(confirmedRound);

            const verifyResponse = await fetch(`${apiURL}/rewards/verify`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: entryID,
                    txn: txId
                })
            });
            const data = await verifyResponse.json();
            if (!data.ok) {
                console.error(data.error);
                setStateFunc('error');
                setModalVisibilityFunc(false);
                return;
            }
        
            setStateFunc('verified');
            setModalVisibilityFunc(false);
            // setTimeout(() => {
                setRewardsFunc(data.data);
            // }, 5000);
        } catch (error) {
            console.error(error);
            setStateFunc('error');
            setModalVisibilityFunc(false);
            return;
        }
    }
}

// async function submit(address, wtype, assets, walletConnect, myAlgoWallet, setStateFunc, setTokenFunc, setModalVisibilityFunc, setCurrentlyStakedFunc) {
//     const response = await fetch(`${apiURL}/stake`, {
//         method: 'POST',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//             address,
//             assets,
//         })
//     });
//     const data = await response.json();
//     if (!data.ok) {
//         console.error(data.error);
//         return;
//     }

//     setTokenFunc(data.token);
//     setModalVisibilityFunc(true);
//     setStateFunc('loading');

//     const suggestedParams = await Algosdk.getTransactionParams().do();
//     const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
//         suggestedParams,
//         from: address,
//         to: rewardVerificationAddress,
//         amount: 0,
//         note: new Uint8Array(Buffer.from(data.token))
//     });

//     if (wtype === 'pera') {
//         const txnToSign = {
//             txn: Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString("base64"),
//             message: 'Akita Rewards Zero Amount Verification Transaction',
//         };
//         const request = formatJsonRpcRequest("algo_signTxn", [[txnToSign]]);
//         try {
//             const result = await walletConnect.sendCustomRequest(request);
//             const signedTxns = result.map(element => new Uint8Array(Buffer.from(element, "base64")));
//             const { txId } = await Algosdk.sendRawTransaction(signedTxns).do();
//             const confirmedRound = await waitForTransaction(txId);
//             // console.log(confirmedRound);

//             const verifyResponse = await fetch(`${apiURL}/verify`, {
//                 method: 'POST',
//                 headers: {
//                     'Accept': 'application/json',
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify({
//                     id: data.token,
//                     txn: txId
//                 })
//             });
//             const verifyData = await verifyResponse.json();
//             if (!verifyData.ok) {
//                 console.error(verifyData.error);
//                 setStateFunc('error');
//                 setModalVisibilityFunc(false);
//                 return;
//             }
        
//             setStateFunc('success');
//             setModalVisibilityFunc(false);
//             setTimeout(() => {
//                 setCurrentlyStakedFunc(assets);
//             }, 5000);
//         } catch (error) {
//             console.error(error);
//             setStateFunc('error');
//             setModalVisibilityFunc(false);
//             return;
//         }
//     } else if (wtype === 'myalgo') {
//         try {
//             const result = await myAlgoWallet.signTransaction([txn.toByte()])
//             const signedTxns = result.map(element => new Uint8Array(element.blob));
//             const { txId } = await Algosdk.sendRawTransaction(signedTxns).do();
//             const confirmedRound = await waitForTransaction(txId);
//             // console.log(confirmedRound);

//             const verifyResponse = await fetch(`${apiURL}/verify`, {
//                 method: 'POST',
//                 headers: {
//                     'Accept': 'application/json',
//                     'Content-Type': 'application/json'
//                 },
//                 body: JSON.stringify({
//                     id: data.token,
//                     txn: txId
//                 })
//             });
//             const verifyData = await verifyResponse.json();
//             if (!verifyData.ok) {
//                 console.error(verifyData.error);
//                 setStateFunc('error');
//                 setModalVisibilityFunc(false);
//                 return;
//             }
        
//             setStateFunc('success');
//             setModalVisibilityFunc(false);
//             setTimeout(() => {
//                 setCurrentlyStakedFunc(assets);
//             }, 5000);
//         } catch (error) {
//             console.error(error);
//             setStateFunc('error');
//             setModalVisibilityFunc(false);
//             return;
//         }
//     }
// }

async function waitForTransaction(txId) {
    let lastStatus = await Algosdk.status().do();
    let lastRound = lastStatus["last-round"];
    while (true) {
        const status = await Algosdk.pendingTransactionInformation(txId).do();
        if (status["pool-error"]) {
            throw new Error(`Transaction Pool Error: ${status["pool-error"]}`);
        }
        if (status["confirmed-round"]) {
            return status["confirmed-round"];
        }
        lastStatus = await Algosdk.statusAfterBlock(lastRound + 1).do();
        lastRound = lastStatus["last-round"];
    }
}

const RewardsSection = ({ address, wtype, showConnectModal, showWalletConnectModal, closeModal, myAlgoWallet, walletConnect }) => {

    const [loading, setLoading] = useState(true);
    const [eligible, setEligible] = useState([]);
    const [ineligible, setIneligible] = useState([]);
    const [currentlyStaked, setCurrentlyStaked] = useState([]);

    // unverified, loading, error, verified
    const [verificationState, setVerificationState] = useState('unverified');
    const [entryID, setEntryID] = useState('');
    const [rewardsToken, setRewardsToken] = useState('');
    const [rewards, setRewards] = useState(null);
    const [revealedIndexes, setRevealedIndex] = useState([]);

    // const [token, setToken] = useState('');
    const [loadingModalShown, setLoadingModalShown] = useState(false);

    const [dinfo, setDinfo] = useState({ text: '', link: '' });
    const [showDisqualified, setShowDisqualified] = useState(false);

    useEffect(() => {
        if (!!address) {
            (async () => {
                try {
                    const stakedResponse = await fetch(`${apiURL}/staked/${address}`);
                    if (stakedResponse.status === 404) {
                        return;
                    }

                    const staked = await stakedResponse.json();
                    const ineligibles = await getStakedInEligible(address, staked.assets);

                    setEntryID(staked.entry_id);
                    console.log(staked.token);
                    if (staked.token !== "") {
                        setRewardsToken(staked.token);
                    }

                    const ineligibleIDs = ineligibles.map(asset => asset.ID);
                    const stillStaked = staked.assets.filter(id => !ineligibleIDs.includes(id));
                    setEligible(stillStaked.map(id => nftDict[id]));
                    setCurrentlyStaked(stillStaked);
                    setIneligible(ineligibles);
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                    return;
                }
            })();
        }
    }, [address]);

    const showDisqualification = (data) => {
        setDinfo({
            text: `Akita #${data.Name} became ineligible for rewards when it left your wallet at round ${data.DisqualifyingRound}`,
            link: `https://algoexplorer.io/tx/${data.DisqualifyingTx}`,
        });
        setShowDisqualified(true);
    }

    const hideDisqualification = () => {
        setShowDisqualified(false);
    }

    // useEffect(() => {
    //     if (submissionState !== 'unsubmitted' && submissionState !== 'loading') {
    //         setSubmissionState('unsubmitted');
    //     }
    // }, [selected])

    // const toggleSelected = (id) => {
    //     if (submissionState !== 'loading') {
    //         selected.includes(id) ? setSelected(selected.filter(item => item !== id)) : setSelected([...selected, id]);
    //     }
    // }

    // const toggleSelectAll = (eligible, selected) => {
    //     if (selected.length === eligible.length) {
    //         setSelected([]);
    //     } else {
    //         setSelected([...eligible.map(nft => nft.ID)])
    //     }
    // }

    return (
        <>
            <div className="goldBackground text-white overflow-hidden">
                <div className="container h-full-screen">
                    {
                        (() => {
                            if (!!!address) {
                                return (
                                    <div className="row h-full-screen d-flex justify-content-center align-items-center">
                                        <div className="text-center text-dark">
                                            <h1>Akita Holding Rewards</h1>
                                            <button
                                                type="button"
                                                aria-current="page"
                                                onClick={() => showConnectModal()}
                                                className="white-connect-button px-3 rounded-pill"
                                            >
                                                Connect
                                            </button>
                                        </div>
                                    </div>
                                );
                            }

                            if (loading) {
                                return (
                                    <div className="row h-full-screen d-flex justify-content-center align-items-center">
                                        <div className="text-center text-dark">
                                            <h1>Loading...</h1>
                                        </div>
                                    </div>
                                );
                            }

                            if (!(eligible.length > 0)) {
                                return (
                                    <div className="row h-full-screen d-flex justify-content-center align-items-center">
                                        <div className="text-center text-dark">
                                            <h1>This wallet doesnt contain any pledged AKC's</h1>
                                            <p>Maybe your AKC's are in a different one?</p>
                                        </div>
                                    </div>
                                );
                            }

                            if (verificationState === 'verified' && !!rewards) {
                                return (
                                    <div className="row g-4">
                                        <div className="col-12 mt-5 text-center text-dark">
                                            <h1>
                                                {
                                                    (currentlyStaked.length > 0)
                                                    ? `${currentlyStaked.length} Successful for staking period #1`
                                                    : `No Pledged AKC's`
                                                }
                                            </h1>
                                            {
                                                (currentlyStaked.length > 0) && <p>Thank you for participating!</p>
                                            }
                                        </div>
                                    
                                        {/* loop */}
                                        {rewards.map(data => ({ data, details: nftDict[data.asset_id]})).map((data, index) => (
                                            <div className="col-12" key={data.details.Name}>
                                                <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center bg-dark-grey p-4 px-10 big-round">
                                                    <div className={`border-0 bg-dark-grey`}>
                                                        <LazyLoad height={400} placeholder={<img src={loadingImg} className="img-fluid" alt="..." />} once>
                                                            <img src={require('../../../images/compressed-nft/AKC' + data.details.Name + '.webp')} loading="lazy" className="mw-100 big-round" alt="" />
                                                        </LazyLoad>
                                                        <div className="card-footer bg-dark-grey pt-3 position-relative overflow-hidden">
                                                            <h2 className="h5 nft-font text-truncate">Akita #{data.details.Name}</h2>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 px-4 d-flex flex-column justify-content-center align-items-center">
                                                        <h1 className="mb-4">
                                                            {(() => {
                                                                switch (data.data.type) {
                                                                    case 'spreadshirt': {
                                                                        return 'You won a $50 Spreadshirt Gift Card!'
                                                                    }
                                                                    case 'budsies': {
                                                                        return 'You won a $250 Budsies Gift Card!'
                                                                    }
                                                                    case 'nifty_slab': {
                                                                        return 'You won a Nifty Slab!'
                                                                    }
                                                                    case 'omen_3d': {
                                                                        return 'You won an Omen 3D Sculpture!'
                                                                    }
                                                                    case 'random_akc': {
                                                                        return 'You won an AKC!'
                                                                    }
                                                                    case 'custom_akc': {
                                                                        return 'You won a Custom AKC!'
                                                                    }
                                                                    case 'goo_akc': {
                                                                        return 'You won a Algoo AKC!'
                                                                    }
                                                                    case 'cursed_akc': {
                                                                        return 'You won a Cursed Collar AKC!'
                                                                    }
                                                                }

                                                            })()}
                                                        </h1>

                                                        {
                                                            (() => {
                                                                if (!revealedIndexes.includes(index)) {
                                                                    return (
                                                                        <button
                                                                            type="button"
                                                                            aria-current="page"
                                                                            onClick={() => setRevealedIndex(x => [...x, index])}
                                                                            className="white-connect-button small rounded-pill"
                                                                        >
                                                                            Claim Reward
                                                                        </button>
                                                                    )
                                                                }

                                                                switch (data.data.type) {
                                                                    case 'spreadshirt': {
                                                                        return (
                                                                            <>
                                                                                <div className="bg-black text-white mb-4 p-4 big-round">
                                                                                    { data.data.code }
                                                                                </div>
                                                                                <div class="text-center mb-4">
                                                                                    <h2>Use this code at checkout</h2>
                                                                                    <a target="_blank" href="https://www.spreadshirt.com">Redeemable Here</a>
                                                                                </div>
                                                                                <span>*For the best image quality on your new apparel make sure you use the direct IPFS link to your NFT!</span>
                                                                            </>
                                                                        )
                                                                    }
                                                                    case 'budsies': {
                                                                        return (
                                                                            <>
                                                                                <div className="bg-black text-white mb-4 p-4 big-round">
                                                                                    { data.data.code }
                                                                                </div>
                                                                                <div class="text-center mb-4">
                                                                                    <h2>Use this code at checkout</h2>
                                                                                    <a target="_blank" href="https://www.budsies.com/budsies-services/">Redeemable Here</a>
                                                                                </div>
                                                                                <span>*For the best image quality on your new plushie make sure you use the direct IPFS link to your NFT!</span>
                                                                            </>
                                                                        )
                                                                    }
                                                                    case 'nifty_slab': {
                                                                        return (
                                                                            <>
                                                                                <div className="bg-black text-white mb-4 p-4 big-round">
                                                                                    { data.data.code }
                                                                                </div>
                                                                                <div className="text-center mb-4">
                                                                                    <h2>Use this code at checkout</h2>
                                                                                    <a target="_blank" href="https://niftyslabs.com/collabs/akita-kennel-club/">Redeemable Here</a>
                                                                                </div>
                                                                                <span>*These codes are only redeemable for a slab of one of your AKCs, you'll need to provide them with an nftexplorer link to your chosen AKC</span>
                                                                            </>
                                                                        )
                                                                    }
                                                                    case 'omen_3d':
                                                                    case 'random_akc':
                                                                    case 'custom_akc':
                                                                    case 'goo_akc':
                                                                    case 'cursed_akc': {
                                                                        return (
                                                                            <>
                                                                                <div class="text-center mb-4">
                                                                                    <h2>You'll need to contact Inkwell on discord in order to redeem this one!</h2>
                                                                                    <a target="_blank" href="https://discordapp.com/users/905287405886656572">Message him here</a>
                                                                                </div>
                                                                                <span>Or hop over to the <a target="_blank" href="https://discord.com/channels/904883897224032256/933079473648173168">akita discord</a> and ask for him there if that link doesn't work for you</span>
                                                                            </>
                                                                        )
                                                                    }
                                                                }                                                                
                                                            })()
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                            
                                    </div>
                                )
                            }

                            if (!!rewardsToken && verificationState !== 'verified') {
                                return (
                                    <div className="row h-full-screen d-flex justify-content-center align-items-center">
                                        <div className="text-center text-dark">
                                            <h1>You have rewards to claim!</h1>
                                            <p>Verify your wallet to claim your rewards!</p>
                                            <button
                                                type="button"
                                                aria-current="page"
                                                onClick={() => verifyWallet(address, rewardsToken, entryID, wtype, walletConnect, myAlgoWallet, setVerificationState, setLoadingModalShown, setRewards)}
                                                className="white-connect-button small rounded-pill"
                                            >
                                                Verify
                                            </button>
                                        </div>
                                    </div>
                                )
                            }

                            return (
                                <div className="row h-full-screen d-flex justify-content-center align-items-center">
                                    <div className="col-12 mt-5 text-center text-dark">
                                        <h1>
                                            {
                                                (currentlyStaked.length > 0)
                                                ? `${currentlyStaked.length} Successful for staking period #1`
                                                : `No Pledged AKC's`
                                            }
                                        </h1>
                                        {
                                            (currentlyStaked.length > 0) && <p>Thank you for participating!</p>
                                        }
                                    </div>
                                    {/* {eligible.map(data => (
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={data.Name}>
                                            <div className={`
                                                selectable-card selected border-0 big-round overflow-hidden bg-dark-grey`}>
                                                <LazyLoad height={400} placeholder={<img src={loadingImg} className="img-fluid" alt="..." />} once>
                                                    <img src={require('../../../images/compressed-nft/AKC' + data.Name + '.webp')} loading="lazy" className="card-img-top" alt="" />
                                                </LazyLoad>
                                                <div className="card-footer bg-dark-grey pt-3 position-relative overflow-hidden">
                                                    <h2 className="h5 nft-font text-truncate">Akita #{data.Name}</h2>
                                                    <span className="selected-cut">
                                                        <i className="selected-icon my-4 fas fa-circle-check fa-2xl"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {ineligible.map(data => (
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={data.Name}>
                                            <div className={`selectable-card dimmed hand border-0 big-round overflow-hidden bg-dark-grey`} onClick={() => showDisqualification(data)}>
                                                <LazyLoad height={400} placeholder={<img src={loadingImg} className="img-fluid" alt="..." />} once>
                                                    <img src={require('../../../images/compressed-nft/AKC' + data.Name + '.webp')} loading="lazy" className="card-img-top" alt="" />
                                                </LazyLoad>
                                                <div className="card-footer bg-dark-grey pt-3 position-relative overflow-hidden">
                                                    <h2 className="h5 nft-font text-truncate">Akita #{data.Name}</h2>

                                                    <span className='unselectable-cut'>
                                                        <i className={`my-4 fas fa-circle-xmark fa-2xl`}></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))} */}
                                </div>
                            )

                        })()
                    }
                </div>
                <svg className="m-minus-1 img-flip" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><g transform="translate(720,174) scale(1,1) translate(-720,-174)"><path d="" fill="rgb(46,49,54)"><animate attributeName="d" dur="19s" repeatCount="indefinite" keyTimes="0;0.333;0.667;1" calcmod="spline" keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1" begin="0s" values="M0 0L 0 214.9848280783939Q 80 242.72337307300558  160 200.05274309068835T 320 248.32458218997903T 480 238.66661821384852T 640 211.51960143889377T 800 207.98735971355623T 960 232.02152666571124T 1120 245.30669005296505T 1280 198.19930544107874T 1440 250.84949882665188L 1440 0 Z;M0 0L 0 223.30027872793684Q 80 217.21585917207992  160 196.99219800755813T 320 235.7018398487831T 480 212.04486641371636T 640 215.35424936215801T 800 217.4303837193541T 960 230.98795985107128T 1120 241.45831217487108T 1280 235.13433305562006T 1440 225.3717148633125L 1440 0 Z;M0 0L 0 254.79151635136407Q 80 231.08654293726624  160 182.13816053818394T 320 206.8510621791531T 480 230.5404442241851T 640 183.52616381182872T 800 253.8776516435699T 960 204.1873984247278T 1120 201.07029069018844T 1280 256.0461840485549T 1440 242.0124681466796L 1440 0 Z;M0 0L 0 214.9848280783939Q 80 242.72337307300558  160 200.05274309068835T 320 248.32458218997903T 480 238.66661821384852T 640 211.51960143889377T 800 207.98735971355623T 960 232.02152666571124T 1120 245.30669005296505T 1280 198.19930544107874T 1440 250.84949882665188L 1440 0 Z"></animate></path></g></svg>
            </div>
            <div className="pt-5 px-3">
                <div className="container small-width">
                    <h1 className="text-center mb-5">FAQ</h1>
                    <div className="accordion big-round" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    How long is the sign up period?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    This sign up period will last a total of two weeks. Beginning on May 1st and ending on the 15th
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How long do i need to hold?
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    A total of 6 weeks, from May 15th to June 26th.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    What do i get for Holding?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <span>AKTA & AKC Rewards</span><br />
                                    At Least 15 million AKTA.<br/>
                                    Total amount for rewards will depend on how much can be secured based on market value at the time of purchases. This AKTA will be split between all successful holders at the end of the rewards period. Amount will be dependent on the rarity of each individual AKC held. Distribution depends on number of AKCs that are held through the entire 6 week period.<br/><br/>
                                    21 AKC's will be awarded to some lucky pledges<br />
                                    &nbsp;&nbsp;- 10 Random AKCS<br />
                                    &nbsp;&nbsp;- 1 Fully Customized AKCS<br />
                                    &nbsp;&nbsp;- 5 Corrupted AKCS<br />
                                    &nbsp;&nbsp;- 5 Algoo AKCS<br />
                                    <br />
                                    <span>Physical Rewards (subject to change depending on availability)</span><br />
                                    There will be 100s of opportunities for holders to earn physical rewards. These rewards include the following:<br />
                                    100 Nifty Slabs (in the form of a redemption code) (Staked legendary rarity AKCs will be guaranteed to receive one.)<br />
                                    20 Custom Plushies (in the form of a digital gift card)<br />
                                    100 Custom t-shirts (in the form of a digital gift card)<br />
                                    1 OMEN 3D NFT (redeemable for a painted 3d print of an AKC)<br /><br />
                                    Digital gift cards are used so that we can collect as little personal information as possible.<br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {
                showDisqualified &&
                <div id="submission-toolbar" className="btn-toolbar bg-dark py-3 px-5 fixed-bottom d-flex justify-content-between align-items-center" role="toolbar" aria-label="">
                    <button
                        type="button"
                        onClick={() => submit(address, wtype, selected, walletConnect, myAlgoWallet, setSubmissionState, setToken, setLoadingModalShown, setCurrentlyStaked)}
                        className="gold-submit-button btn-sm ms-0 ms-md-4 px-3 rounded-pill"
                        aria-current="page"
                    >
                        Submit
                    </button>
                    <span className="h5 nft-font mb-0">
                        <span className="text-white">{dinfo.text}</span>
                    </span>

                    <a href={dinfo.link} target="_BLANK">
                        AlgoExplorer
                        <i className={`mx-2 my-4 fas fa-arrow-up-right-from-square align-self-end`}></i>
                    </a>

                    <button
                        type="button"
                        className="hand bg-transparent border-0 text-white"
                        onClick={() => hideDisqualification()}
                    >
                        <i className={`my-4 fas fa-circle-xmark fa-2xl align-self-end`}></i>
                    </button>
                </div>
            } */}

            <VerifyModal shown={loadingModalShown} setShown={setLoadingModalShown} address={address} token={rewardsToken} />
            {
                (() => {
                    if (loadingModalShown) {
                        switch (verificationState) {
                            case 'loading': {
                                return (
                                    <div id="submission-toolbar" className="btn-toolbar bg-dark py-3 px-5 fixed-bottom d-flex justify-content-center align-items-center" role="toolbar" aria-label="">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <svg role="status" className="loading-spinner" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                            <h6 className="text-white">Awaiting Transaction</h6>
                                        </div>
                                    </div>
                                )
                            }
                            case 'error': {
                                return (
                                    <div id="submission-toolbar" className="btn-toolbar bg-dark py-3 px-5 fixed-bottom d-flex justify-content-center align-items-center" role="toolbar" aria-label="">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <i className={`my-4 fas fa-circle-xmark fa-2xl`}></i>
                                            <h3 className="text-white">Something went wrong</h3>
                                        </div>
                                    </div>
                                )
                            }
                            case 'verified': {
                                return (
                                    <div id="submission-toolbar" className="btn-toolbar bg-dark py-3 px-5 fixed-bottom d-flex justify-content-center align-items-center" role="toolbar" aria-label="">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <i className={`my-4 fas fa-circle-check fa-2xl`}></i>
                                            <h3 className="text-white">Verified!</h3>
                                        </div>
                                    </div>
                                )
                            }
                            default: {
                                return '';
                            }

                        }
                    }
                })()
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    address: state.connect?.address,
    wtype: state.connect?.wtype,
    myAlgoWallet: myAlgoWallet,
    walletConnect: walletConnect
});

const mapDispatchToProps = (dispatch) => ({
    showConnectModal: () => dispatch(openConnectModal()),
    showWalletConnectModal: () => dispatch(openWalletConnectModal()),
    closeModal: (id) => dispatch(closeModal(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardsSection);