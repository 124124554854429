import React from 'react'
import NFTSection from '../components/Sections/NFTSection'


const NFT = () => {
    return (
        <>
            <NFTSection />
        </>
    )
}

export default NFT
