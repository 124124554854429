import React from 'react'
import RewardsSection from '../components/Sections/RewardsSection'


const Rewards = () => {
    return (
        <>
            <RewardsSection />
        </>
    )
}

export default Rewards
