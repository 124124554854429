import React, { useRef, useCallback, useEffect } from 'react';
import { Background, ModalWrapper, CloseModalButton, TextWrapper, TokenWrapper, ModalHeader } from './VerifyModalElements';

const VerifyModal = ({ address, token, shown, setShown }) => {

    function shortenAddress(address) {
        return address.substring(0, 6) + '...' + address.substring(address.length - 6);
    }

    if (!shown) {
        return (<></>)
    }

    return (
        <Background onClick={() => setShown(false)}>
            <ModalWrapper>
                <CloseModalButton onClick={() => setShown(false)} />
                <ModalHeader>
                    Verify your wallet
                </ModalHeader>
                <TextWrapper>
                    <p>
                        For security purposes we need to verify you own the wallet connected to this site, Please sign the $0 transaction from your wallet.
                        <br/>
                        Please verify that the transaction has this token below in the notes field.
                    </p>
                    <TokenWrapper>{ token }</TokenWrapper>
                    <p>
                        You'll need atleast 0.001 ALGO for the transaction cost of verifying your wallet.
                        <br/>
                        Make sure you send the transaction from the wallet thats currently connected ({shortenAddress(address)})
                    </p>

                </TextWrapper>
            </ModalWrapper>
        </Background>
    )
}

export default VerifyModal;
