import React, { useState, useEffect } from 'react';

import NftFilterData from '../../../details.json';
import NftData from '../../../data.json';
import LazyLoad, { forceVisible } from "react-lazyload";

import loadingImg from '../../../images/loading.webp';

import './index.scss';
import NFTModal from '../../NFTModal';

import algosdk from 'algosdk';
import { connect } from 'react-redux';
import { openConnectModal } from '../../../services/actions/actions';
import { myAlgoWallet } from '../../../services/reducers/connect/connect'

function base64Encode(input) {
    return btoa(input.reduce((str, byte) => str + String.fromCharCode(byte), ""));
}

const NFTSection = ({address, myAlgoWallet}) => {

    useEffect(() => {
        setAttributes()
    });

    function setQueryStringParameter(name, value) {
        const params = new URLSearchParams(window.location.search);
        params.set(name, value);
        window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}?${params}`));
    }

    const [getSearch, setSearch] = useState('');
    const [getHead, setHead] = useState('');
    const [getEyes, setEyes] = useState('');
    const [getSnout, setSnout] = useState('');
    const [getBody, setBody] = useState('');
    const [getBack, setBack] = useState('');
    const [getSkin, setSkin] = useState('');
    const [getBackground, setBackground] = useState('');
    const [getRarity, setRarity] = useState('');

    const resetSelect = () => {
        window.history.pushState({}, document.title, window.location.pathname);

        setSearch("");
        setHead("");
        setEyes("");
        setSnout("");
        setBody("");
        setBack("");
        setSkin("");
        setBackground("");
        setRarity("");

        document.getElementById("Search").value = "";
        document.getElementById("Head").selectedIndex = 0;
        document.getElementById("Eyes").selectedIndex = 0;
        document.getElementById("Snout").selectedIndex = 0;
        document.getElementById("Body").selectedIndex = 0;
        document.getElementById("Back").selectedIndex = 0;
        document.getElementById("Skin").selectedIndex = 0;
        document.getElementById("Background").selectedIndex = 0;
        document.getElementById("Rarity").selectedIndex = 0;
    }   
    
    const handleSearch = (event) => {
        setQueryStringParameter(event.target.id.toLowerCase(), event.target.value);
        setSearch(event.target.value);
        forceVisible();
    }

    const handleSelect = (event) => {
        setQueryStringParameter(event.target.id.toLowerCase(), event.target.value);

        if(event.target.id==='Head'){
            setHead(event.target.value);
        }
        if(event.target.id==='Eyes'){
            setEyes(event.target.value);
        }
        if(event.target.id==='Snout'){
            setSnout(event.target.value);
        }
        if(event.target.id==='Body'){
            setBody(event.target.value);
        }
        if(event.target.id==='Back'){
            setBack(event.target.value);
        }
        if(event.target.id==='Skin'){
            setSkin(event.target.value);
        }
        if(event.target.id==='Background'){
            setBackground(event.target.value);
        }
        if(event.target.id==='Rarity'){
            setRarity(event.target.value);
        }
        if(
          event.target.id==='Head' ||
          event.target.id==='Eyes'||
          event.target.id==='Snout' ||
          event.target.id==='Body' || 
          event.target.id==='Back' ||
          event.target.id==='Skin'||
          event.target.id==='Background'||
          event.target.id==='Rarity'
        ) {
            forceVisible();
        }
    };

    const setAttributes = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const search = urlParams.get('search');
        const head = urlParams.get('head');
        const eyes = urlParams.get('eyes');
        const snout = urlParams.get('snout');
        const body = urlParams.get('body');
        const back = urlParams.get('back');
        const skin = urlParams.get('skin');
        const background = urlParams.get('background');
        const rarity = urlParams.get('rarity');

        if (!!search) {
            setSearch(search);
            document.getElementById("Search").value = search;
        }
        if (!!head) {
            setHead(head);
            document.getElementById("Head").value = head;
        }
        if (!!eyes) {
            setEyes(eyes);
            document.getElementById("Eyes").value = eyes;
        }
        if (!!snout) {
            setSnout(snout);
            document.getElementById("Snout").value = snout;
        }
        if (!!body) {
            setBody(body);
            document.getElementById("Body").value = body;
        }
        if (!!back) {
            setBack(back);
            document.getElementById("Back").value = back;
        }
        if (!!skin) {
            setSkin(skin);
            document.getElementById("Skin").value = skin;
        }
        if (!!background) {
            setBackground(background);
            document.getElementById("Background").value = background;
        }
        if (!!rarity) {
            setRarity(rarity);
            document.getElementById("Rarity").value = rarity;
        }
        
        if (!!search || !!head || !!eyes || !!snout || !!body || !!back || !!skin || !!background || !!rarity) {
            forceVisible();
        }
    }
    
    const filteredNFT = NftData.filter(
      row =>
        (row.Name.includes(getSearch) || getSearch === "") &&
        (row.Head === getHead || getHead === "") &&
        (row.Eyes === getEyes || getEyes === "") &&
        (row.Snout === getSnout || getSnout === "") &&
        (row.Body === getBody || getBody === "") &&
        (row.Back === getBack || getBack === "") &&
        (row.Skin === getSkin || getSkin === "") &&
        (row.Background === getBackground || getBackground === "") &&
        (row.Rarity === getRarity || getRarity === "")
    );

    const [modalTitle, setModalTitle] = useState("Welcome");
    const [modalImg, setModalImg] = useState(loadingImg);
    const [modalData, setModalData] = useState("Loading...");

    const [showArcUpload, setShowArcUpload] = useState(false);

    function hasArcData(nft) {
        return false;
    }

    async function addArcData(nft) {
        const algodClient = new algosdk.Algodv2('', 'https://node.algoexplorerapi.io', 443);
        const params = await algodClient.getTransactionParams().do();

        let properties;
        if ("Special" in nft) {
            const { Special, Rarity } = nft;
            properties = { Special, Rarity };
        } else {
            const { Skin, Head, Eyes, Snout, Body, Back, Background, Rarity } = nft;
            properties = { Skin, Head, Eyes, Snout, Body, Back, Background, Rarity };
        }

        console.log(properties);

        const arcNote = JSON.stringify({
            standard: "arc69",
            mime_type: "image/jpeg",
            properties,
        })
        const enc = new TextEncoder();
        const note = enc.encode(arcNote);

        const txn = algosdk.makeAssetConfigTxnWithSuggestedParamsFromObject({
            suggestedParams: {
                ...params,
            },
            from: address,
            amount: 0,
            assetIndex: nft.ID,
            type: "acfg",
            reserve: "AKCTRDK4OWNWHTPH4XPKLNWNLZ333VE35SKQ4FGQK3ZJA4FIHCLTRG3PFI",
            manager: "AKCTRDK4OWNWHTPH4XPKLNWNLZ333VE35SKQ4FGQK3ZJA4FIHCLTRG3PFI",
            note,
            strictEmptyAddressChecking: false,
        })

        console.log(txn);

        const signed_txn = await myAlgoWallet.signTransaction(txn.toByte());
        await algodClient.sendRawTransaction(signed_txn.blob).do();
    }

    useEffect(() => {
        if (address === "AKCTRDK4OWNWHTPH4XPKLNWNLZ333VE35SKQ4FGQK3ZJA4FIHCLTRG3PFI") {
            setShowArcUpload(true)
        }
    }, [address]);

    return (
        <div className="py-5 px-3">
            <div className="container">
                <div className="row align-items-start mb-3">
                    <div className="col-6 col-md-4 col-lg-3">
                        <small className="text-white-50 ps-2 mb-3">Search</small><br/>
                        <div className="form-input">
                            <input id="Search" name="Search" onChange={handleSearch}></input>
                        </div>
                    </div>
                </div>

                <div className="row align-items-end g-4 mb-5" id="filters">
                    {Object.keys(NftFilterData).map((key) => (
                        <div className="col-6 col-md-4 col-lg-3" key={key}>
                            <small className="text-white-50 ps-2 mb-3">{ key }</small><br/>
                            <select className="form-select text-capitalize" id={key} name={key} onChange={handleSelect}>
                                <option value=''>Select</option>
                                {NftFilterData[key].map((filter) => (
                                    <option value={filter} key={filter}>{filter}</option>
                                ))}
                            </select>
                        </div>
                    ))}
                    <div className="col-12 col-md-4 col-lg-3">
                        <button
                            className="btn big-round border-2 btn-outline-light opacity-half w-100"
                            onClick={() => {resetSelect()}}
                        >
                            <svg aria-hidden="true" width="14" focusable="false" data-prefix="far" data-icon="times-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="m-minus-1">
                                <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
                            </svg>
                         Reset
                        </button>
                    </div>
                </div>
                <div className="row g-4" id="results">
                    {filteredNFT.map(data => (
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={data.Name}>
                        <div className="card border-0 big-round overflow-hidden hand" data-bs-toggle="modal" data-bs-target="#nftModal" onClick={() => setModalTitle(data.Name) + setModalData(data) + setModalImg(require('../../../images/compressed-nft/AKC' + data.Name + '.webp'))}>
                            <LazyLoad height={400} placeholder={<img src={loadingImg}  className="img-fluid" alt="..." />} once>
                                <img src={require('../../../images/compressed-nft/AKC' + data.Name + '.webp')} loading="lazy" className="card-img-top" alt="" />
                            </LazyLoad>
                            <div className="card-footer bg-dark-grey pt-3 position-relative overflow-hidden">
                                <h2 className="h5 nft-font text-truncate">Akita #{data.Name}</h2>
                                <span className={data.Rarity.toLowerCase()}>{data.Rarity}</span>

                                {
                                    (showArcUpload
                                    && !hasArcData(data))
                                    && <button
                                        className="btn big-round border-2 btn-outline-light opacity-half w-100"
                                        onClick={() => {addArcData(data)}}
                                    >
                                        Upload Arc Data
                                    </button>
                                }

                            </div>
                        </div>
                    </div>
                    ))}
                    {filteredNFT.length === 0 && <div className="pt-5 text-center"><h1>404 Akita Not Found</h1><p>Who let the dogs out? Try another combination.</p></div>}
                </div>
            </div>
            <NFTModal modalTitle={modalTitle} modalImg={modalImg} modalData={modalData} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    address: state.connect?.address,
    myAlgoWallet: myAlgoWallet,
});

const mapDispatchToProps = (dispatch) => ({
    showConnectModal: () => dispatch(openConnectModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NFTSection);
